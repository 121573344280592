import React from 'react'
import { graphql } from 'gatsby'

import LayoutDefault from '../layouts/Default'
import Seo from '@components/seo/Index'
import HomeOurWorks from '@components/pages/home/OurWorks'
import HomeProductsSlider from '@components/pages/home/ProductsSlider'
import HomeDigitalMarketing from '@components/pages/home/DigitalMarketing'
import HomeReviews from '@components/pages/home/Reviews'
import HomeAwardWinning from '@components/pages/home/AwardWinning'
import HomeClientele from '@components/pages/home/Clientele'
import HomeOnTheScene from '@components/pages/home/OnTheScene'
import HomePartnerships from '@components/pages/home/Partnerships'
import HomeSocialMedia from '@components/pages/home/SocialMedia'
import ProductAccessAlt from '@components/global/product/AccessAlt'
import ProductQuizComparison from '@components/global/product/QuizComparison'
import HomeBannerAlt from '@components/pages/home/BannerAlt'

const AltHomepage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <LayoutDefault location={location} title={siteTitle}>
      <Seo title="Homepage Alternative" />
      <HomeBannerAlt />
      <HomeOurWorks />
      <HomeProductsSlider />
      <HomeDigitalMarketing />
      <ProductQuizComparison />
      <HomeReviews />
      <HomeAwardWinning />
      <HomeClientele />
      <HomeOnTheScene />
      <HomePartnerships />
      <HomeSocialMedia />
      <ProductAccessAlt />
    </LayoutDefault>
  )
}

export default AltHomepage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
