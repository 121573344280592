import React, { useRef } from 'react'
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import parse from 'html-react-parser'

import ModalForm from '@components/global/modal/Form'
import ModalVideo from '@components/global/modal/Video'
import DeviceTablet from '@components/global/devices/Tablet'
import FormFreeConsultation from '@components/global/forms/FreeConsultation'
import SvgLogoMono from '@svg/mono.inline'
import SvgPlay from '@svg/play.inline'
import * as cx from './BannerAlt.module.scss'

const HomeBannerAlt = () => {
  const modalFunc = useRef()
  const modalVideoFunc = useRef()

  const items = [
    {
      id: '123',
      title: 'Our Solutions. <br />Your Success.',
      description:
        'Spark your digital transformation with best-in-class real estate marketing.',
      buttonText: 'Boost your online presence',
    },
    {
      id: '456',
      title: 'The Best Agents <br>Use Agent Image. ',
      description:
        'Be an A-list agent with our award-winning website designs and results-driven digital marketing campaigns.',
      buttonText: 'UNLOCK NEXT-LEVEL GROWTH!',
    },
    {
      id: '789',
      title: 'Designed <br>To Dominate.',
      description:
        'Spark your digital transformation with best-in-class real estate marketing.',
      buttonText: 'GET A WEBSITE MAKEOVER',
    },
  ]

  return (
    <section id="banner-alt" className={cx.wrapper}>
      <ModalForm
        childFunc={modalFunc}
        isLabelHidden={true}
        title="Ready To Build <br />An Award-Winning <br />Website?"
        template="alternative"
      >
        <FormFreeConsultation
          submitLabel="UNLOCK NEXT-LEVEL GROWTH!"
          isLabelHidden={true}
          isEmailPhoneMerger={true}
        />
      </ModalForm>
      <ModalVideo
        childFunc={modalVideoFunc}
        url="https://player.vimeo.com/video/696379765"
      />
      <SvgLogoMono className={cx.mono} />

      <Splide
        aria-label="Main Banner"
        hasTrack={false}
        tag="div"
        options={{
          type: 'fade',
          arrows: false,
          pagination: true,
          perPage: 1,
          lazyLoad: 'sequential',
          autoplay: true,
          interval: 5000,
          autoHeight: false,
        }}
        className={cx.items}
      >
        <SplideTrack>
          {items.map((item, index) => {
            return (
              <SplideSlide key={item.id}>
                <ul className={cx.item}>
                  <li>
                    <h2 className="heading-1">{parse(item.title)}</h2>
                    <p className="subtitle-5">{parse(item.description)}</p>
                    <button
                      className="primary-button large"
                      onClick={(e) => modalFunc.current.openModal()}
                    >
                      {item.buttonText}
                    </button>
                  </li>
                  <li>
                    <div className={cx.devices}>
                      <canvas
                        width={825}
                        height={370}
                        className={cx.placeholder}
                      ></canvas>
                      <DeviceTablet
                        imageSrc="https://resources.agentimage.com/redesign/local/slider-item-1.jpg"
                        className={cx.upper}
                      />
                      <DeviceTablet
                        imageSrc="https://resources.agentimage.com/redesign/local/slider-item-2.jpg"
                        className={cx.middle}
                      />
                      <DeviceTablet
                        imageSrc="https://resources.agentimage.com/redesign/local/slider-item-3.jpg"
                        className={cx.lower}
                      />
                    </div>
                  </li>
                </ul>
              </SplideSlide>
            )
          })}
        </SplideTrack>
      </Splide>

      <div className={cx.autoscroll}>
        <Splide
          aria-label="Clients"
          hasTrack={false}
          tag="div"
          extensions={{ AutoScroll }}
          options={{
            type: 'loop',
            rewind: true,
            arrows: false,
            pagination: false,
            autoHeight: false,
            drag: false,
            perPage: 10,
            autoScroll: {
              speed: 2,
              pauseOnHover: false,
              pauseOnFocus: false,
              rewind: true,
            },
          }}
          className={cx.autoCarousel}
        >
          <SplideTrack>
            <SplideSlide>
              <img
                src="https://resources.agentimage.com/redesign/local/logo-1.png"
                alt="Logo 1"
              />
            </SplideSlide>
            <SplideSlide>
              <img
                src="https://resources.agentimage.com/redesign/local/logo-2.png"
                alt="Logo 2"
              />
            </SplideSlide>
            <SplideSlide>
              <img
                src="https://resources.agentimage.com/redesign/local/logo-3.png"
                alt="Logo 3"
              />
            </SplideSlide>
            <SplideSlide>
              <img
                src="https://resources.agentimage.com/redesign/local/logo-4.png"
                alt="Logo 4"
              />
            </SplideSlide>
            <SplideSlide>
              <img
                src="https://resources.agentimage.com/redesign/local/logo-5.png"
                alt="Logo 5"
              />
            </SplideSlide>
            <SplideSlide>
              <img
                src="https://resources.agentimage.com/redesign/local/logo-1.png"
                alt="Logo 1"
              />
            </SplideSlide>
            <SplideSlide>
              <img
                src="https://resources.agentimage.com/redesign/local/logo-2.png"
                alt="Logo 2"
              />
            </SplideSlide>
            <SplideSlide>
              <img
                src="https://resources.agentimage.com/redesign/local/logo-3.png"
                alt="Logo 3"
              />
            </SplideSlide>
            <SplideSlide>
              <img
                src="https://resources.agentimage.com/redesign/local/logo-4.png"
                alt="Logo 4"
              />
            </SplideSlide>
            <SplideSlide>
              <img
                src="https://resources.agentimage.com/redesign/local/logo-5.png"
                alt="Logo 5"
              />
            </SplideSlide>
          </SplideTrack>
        </Splide>

        <div
          className={cx.videoThumbnail}
          role="button"
          onClick={(e) => modalVideoFunc.current.openModal()}
        >
          <img
            src="https://resources.agentimage.com/redesign/local/video-thumbnail.jpg"
            alt="Why AgentImage"
            width={236}
            height={177}
          />
          <div>
            <span>
              <SvgPlay />
            </span>{' '}
            <em>Why Agent Image</em>
          </div>
        </div>
      </div>

      <div className={cx.cta}>
        <div className={cx.ctaContainer}>
          <div className={cx.formHeading}>
            <h2>
              <span className="subtitle-6">Get In Touch With Us For A</span>
              <span className="subtitle-2">Free Consultation</span>
            </h2>
          </div>
          <div className={cx.formLine}></div>
          <div className={cx.form}>
            <FormFreeConsultation
              submitLabel="I want a stunning website!"
              isLabelHidden={true}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeBannerAlt
